import React, {useState, useEffect} from 'react';
import ReactGA from 'react-ga4';
import './App.css';
import LetterboardInput from "./Components/LetterboardInput/LetterboardInput";
import LetterboardOutput from "./Components/LetterboardOutput/LetterboardOutput";

function App() {

    const defaultInput = "Edit here"
    const [output, setOutput] = useState(defaultInput)
    const [fontSize, setFontSize] = useState(20)
    const [boardWidth, setBoardWidth] = useState(500)
    const [boardHeight, setBoardHeight] = useState(500)

    const TRACKING_ID = 'G-3RN2NC8QBZ';

    ReactGA.initialize(TRACKING_ID)

    const trackEvent = (category, action, label) => {
        console.log("Logging GA Event:", category, ":", action, ":", label);
        ReactGA.event({
            category, action, label
        })
    }

    useEffect(() => {
        console.log("page loaded")
        ReactGA.send({ hitType: 'pageview', page: window.location.pathname})
    }, [])

    return (
    <div className="App">
      <div className="letterboard-section">
        <LetterboardInput
            defaultInput={defaultInput}
            setOutput={setOutput}
            fontSize={fontSize}
            setFontSize={setFontSize}
            boardWidth={boardWidth}
            boardHeight={boardHeight}
            setBoardWidth={setBoardWidth}
            setBoardHeight={setBoardHeight}
        />
        <LetterboardOutput
            output={output}
            boardWidth={boardWidth}
            boardHeight={boardHeight}
            fontSize={fontSize}
        />
      </div>
    </div>
  );
}

export default App;
