import React from 'react';
import './LetterboardOutput.scss'
const LetterboardOutput = (props) => {

    const {output, boardWidth, boardHeight, fontSize} = props;
    return (
        <div className="letterboard-output-container">
            <div className="letterboard-output-section" style={{width: `${boardWidth}px`, height: `${boardHeight}px`, fontSize: `${fontSize}px`}}>
                {output}
            </div>
        </div>
    )
}

export default LetterboardOutput;