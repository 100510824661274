import React from 'react';
import './LetterboardInput.scss'
const LetterboardInput = (props) => {

    const {setOutput, defaultInput, fontSize, setFontSize, boardWidth, setBoardWidth, boardHeight, setBoardHeight} = props;

    return (
        <div className="letterboard-input-container">
            Input
            <div className="letterboard-input-section">
                <div>
                    <label>Input</label>
                    <input defaultValue={defaultInput} onChange={(e) => setOutput(e.target.value)} />
                </div>
                <div>
                    <label>Font Size</label>
                    <input defaultValue={fontSize} onChange={(e) => setFontSize(e.target.value)} />
                </div>
                <div>
                    <label>Width</label>
                    <input defaultValue={boardWidth} onChange={(e) => setBoardWidth(e.target.value)} />
                </div>
                <div>
                    <label>Height</label>
                    <input defaultValue={boardHeight} onChange={(e) => setBoardHeight(e.target.value)} />
                </div>
            </div>
        </div>
    )
}

export default LetterboardInput;